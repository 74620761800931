.list__container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;

  max-width: 660px;
}

.list__header {
  width: 48%;
  height: 50px;

  display: flex;
  align-items: center;
}

.list__buttons {
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

@media (min-width: 1250px) {
  .list__buttons {
    width: 50%;
  }
}

@media (max-width: 1250px) {
  .list__container {
    justify-content: center;
  }
  .list__header {
    width: 90%;
  }
  .list__buttons {
    width: 90%;
  }
  .list {
    width: 90%;
  }
}
