#root {
}

body {
  background-color: rgb(var(--background));
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-top: 50px;
}

.container__menu {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (min-width: 1250px) {
  .container {
    flex-direction: row;
    justify-content: center;
    gap: 380px;

    width: 100%;
  }

  .container__menu {
    width: 50%;
    max-width: 351px;
  }

  .list__container {
    width: 100%;
  }
}

@media (max-width: 1250px) {
  .container {
    align-items: center;
  }
  .container__menu {
    width: 90%;
  }
}
