.total-price__container {
  width: 100%;
  height: 96px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #e9ecef;
  border-radius: 4px;
}

.total-price__info {
  width: 70%;
  height: 100%;

  margin-left: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.total-price__price {
  width: 30%;
}

.price {
  color: rgba(var(--color-primary), 1);
}

@media (min-width: 768px) {
  .total-price__container {
    width: 100%;
  }
}
