.homepage {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 50px;

  background-color: rgba(var(--grey-4), 1);
}

.info {
  display: flex;
  flex-direction: column;
  gap: 40px;

  width: 346px;
  height: 274px;

  color: #fff;
}

.info img {
  width: 122px;
}

.info h1 {
  font-size: 38px;
}

@media (max-width: 1000px) {
  .logo {
    display: none;
  }

  .info {
    padding: 20px;
  }
}
