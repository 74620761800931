.card__container {
  width: 100%;
  height: 87px;

  display: flex;

  background-color: rgb(var(--grey-1));

  border-radius: 4px;
}

.Entrada {
  border-left: 4px solid rgb(var(--color-secondary));
}

.Despesa {
  border-left: 4px solid rgb(var(--grey-2));
}

.card__info {
  width: 70%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  margin-left: 10px;
}

.card__price {
  width: 30%;
  margin-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
