header {
  width: 100%;
  height: 88px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25);
}

header img {
  width: 120px;
}

.header__container {
  width: 100%;
  max-width: 1390px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 15px;
}
